import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import Footer from "../components/Footer";
import loaderImage from "../images/loading.gif";
import "../assets/css/donate-styles.css";

const DonatePage = () => {
  return (
    <Layout>
      <Helmet>
        {/* <script src="https://act.actionfordemocracy.org/widgets/v4/fundraising/donate-hungarian-spring?format=js&source=widget&referrer=group-action-for-democracy"></script> */}
        <script src="https://actionnetwork.org/widgets/v4/fundraising/donate-hungarian-spring?format=js&source=widget&referrer=group-action-for-democracy"></script>
      </Helmet>
      <NavOne />
      <div
        id="can-fundraising-area-donate-hungarian-spring"
        style={{ width: "100%" }}
      >
        <div className="donate-loading-holder d-flex w-100 h-100">
          <p className="text-dark m-auto">
            <img src={loaderImage} alt="load-spinner" width={40} height={40} />
            Loading the donation page, please wait...
          </p>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default DonatePage;
